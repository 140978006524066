<template>
    <div class="pt-7 container-fluid" v-if="profile.superadmin">
        <b-row>
            <b-col md="4">
                <b-card no-body class="p-4" v-loading="loading.setting">
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form @submit.prevent="onSubmit('setting')" autocomplete="off">
                            <h6 class="heading-small text-muted mb-4">{{$t('setting.general')}}</h6>
                            <div class="pl-lg-4">
                                <base-input :label="$t('general.lang')" name="lang" :nameAs="$t('general.lang')" :rules="'required'">
                                    <el-select v-model="settingData.lang" multiple :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                        <el-option v-for="(item, key) in langList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                                    </el-select>
                                </base-input>
                                <hr class="my-4">
                                <div class="text-right">
                                    <base-button type="primary" native-type="submit" :disabled="invalid">{{$t('save')}}</base-button>
                                </div>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card no-body class="p-4" v-loading="loading.mail">
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form @submit.prevent="onSubmit('mail')" autocomplete="off">
                            <h6 class="heading-small text-muted mb-4">{{$t('setting.mail')}}</h6>
                            <div class="pl-lg-4">
                                <base-input type="text" name="host" :nameAs="$t('setting.host')" :label="$t('setting.host')+'*'" :placeholder="$t('setting.host')" v-model="mailData.host" required>
                                </base-input>
                                <base-input type="text" name="username" :nameAs="$t('setting.username')" :label="$t('setting.username')+'*'" :placeholder="$t('setting.username')" v-model="mailData.username" required>
                                </base-input>
                                <base-input type="password" name="password" :nameAs="$t('setting.password')" :label="$t('setting.password')+'*'" :placeholder="$t('setting.password')" v-model="mailData.password" required>
                                </base-input>
                                <base-input type="text" name="port" :nameAs="$t('setting.port')" :label="$t('setting.port')+'*'" :placeholder="$t('setting.port')" v-model="mailData.port" required>
                                </base-input>
                                <hr class="my-4">
                                <div class="text-right">
                                    <base-button type="primary" native-type="submit" :disabled="invalid">{{$t('save')}}</base-button>
                                </div>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card no-body class="p-4" v-loading="loading.sms">
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form @submit.prevent="onSubmit('sms')" autocomplete="off">
                            <h6 class="heading-small text-muted mb-4">{{$t('setting.sms')}}</h6>
                            <div class="pl-lg-4">
                                <base-input type="text" name="header" :nameAs="$t('setting.sms_header')" :label="$t('setting.sms_header')+'*'" :placeholder="$t('setting.sms_header')" v-model="smsData.header" required>
                                </base-input>
                                <base-input type="text" name="sms_host" :nameAs="$t('setting.sms_host')" :label="$t('setting.sms_host')+'*'" :placeholder="$t('setting.host')" v-model="smsData.url" required>
                                </base-input>
                                <base-input type="text" name="username" :nameAs="$t('setting.username')" :label="$t('setting.username')+'*'" :placeholder="$t('setting.username')" v-model="smsData.usercode" required>
                                </base-input>
                                <base-input type="password" name="password" :nameAs="$t('setting.password')" :label="$t('setting.password')+'*'" :placeholder="$t('setting.password')" v-model="smsData.password" required>
                                </base-input>
                                <hr class="my-4">
                                <div class="text-right">
                                    <base-button type="primary" native-type="submit" :disabled="invalid">{{$t('save')}}</base-button>
                                </div>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { SETTING_UPDATE, SETTING_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;
export default {
    computed: {
        ...mapGetters({ setting: 'generalSetting', mail: 'mailSetting', sms: 'smsSetting', profile: 'getProfile' })
    },
    data() {
        return {
            langList: ['tr', 'en', 'de', 'ru'],
            loading: { setting: false, mail: false, sms: false },
            settingData: {
                lang: []
            },
            mailData: {},
            smsData: {}
        };
    },
    methods: {
        onSubmit(id) {
            self.loading[id] = true;
            let data = id == 'setting' ? self.settingData : id == 'mail' ? self.mailData : self.smsData;
            self.$store
                .dispatch(SETTING_UPDATE, { id, setting: data })
                .then(resp => {
                    self.loading[id] = false;
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.loading[id] = false;
                    handleError(self, err);
                });
        },
        getData() {
            self.loading = { setting: true, mail: true, sms: true };
            self.$store
                .dispatch(SETTING_REQUEST)
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = { setting: false, mail: false, sms: false };
                });
        }
    },
    watch: {
        setting: function(n, o) {
            if (n != o && n) {
                self.settingData = _.cloneDeep(n);
            }
        },
        mail: function(n, o) {
            if (n != o && n) {
                self.mailData = _.cloneDeep(n);
            }
        },
        sms: function(n, o) {
            if (n != o && n) {
                self.smsData = _.cloneDeep(n);
            }
        },
        profile: function(n, o) {
            if (n.superadmin == undefined || !n.superadmin) {
                self.$router.push({ name: 'permissionDenied' });
            }
        }
    },
    created() {
        self = this;
        self.getData();
        if (self.profile.uuid != undefined) {
            if (self.profile.superadmin == undefined || !self.profile.superadmin) {
                self.$router.push({ name: 'permissionDenied' });
            }
        }
    }
};
</script>
